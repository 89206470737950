.projects{
    margin: 20px 0;
    display: block;
}

.projects .heading{
    padding: 25px 0;
    text-align: center;
    margin-bottom: 20px;
}

.projects .heading h2{
    font-size: 42px;
    font-weight: 800;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-color: #b4b4b4;
    text-underline-offset: 18px;
}

.projects .block-container{
    margin: 0 auto 65px;
    display: block;
    justify-content: center;
}

.projects .block-section{
    padding: 25px 0;
    display: flex;
    column-gap: 30px;
}

.projects .block-section .block-elements{
    margin: auto 0;
}

.projects .block-section .block-elements .block-list{
    width: 70%;
    margin: auto;
}

.projects .block-section .block-elements .block-list h3{
    font-weight: 600;
}

.projects .block-section .block-elements img{
    height: 325px;
    width: 550px;
    margin: auto;
    display: block;
}

.projects .block-section .block-elements .block-list button{
    font-size: 12px;
    padding: 8px 20px;
}


/* Media queries for tablet */
@media screen and (max-width: 768px) {

    .projects .heading{
        padding: 25px 0 0;
    }

    .projects .heading h2{
        font-size: 28px;
        line-height: 1.4;
    }

    .projects .block-section{
        flex-direction: column-reverse;
        row-gap: 25px;
    }

    .projects .block-section .block-elements img {
        width: 100%;
        height: auto;
    }

    .projects .block-section .block-elements .block-list {
        width: 100%;
        text-align: left;
    }

    .projects .block-section .block-elements .block-list h3{
        font-size: 20px;
        letter-spacing: 0.5px;
    }

    .projects .block-section .block-elements .block-list p{
        font-size: 16px;
        margin-bottom: 18px;
    } 

    .projects .block-section .block-elements .block-list button {
        font-size: 14px;
        padding: 9px 22px;
    }

}

/* Media queries for mobile */
@media screen and (max-width: 480px) {

    .projects .heading{
        padding: 25px 0 0;
    }

    .projects .heading h2{
        font-size: 28px;
        line-height: 1.4;
        /* padding-bottom: 20px; */
    }

    .projects .block-section{
        flex-direction: column-reverse;
        row-gap: 25px;
    }

    .projects .block-section:nth-child(1){
        padding-bottom: 35px;
        border-bottom: 1px solid #b4b4b4;
    }

    .projects .block-section .block-elements img {
        width: 100%;
        height: auto;
    }

    .projects .block-section .block-elements .block-list {
        width: 100%;
        text-align: left;
    }

    .projects .block-section .block-elements .block-list button {
        font-size: 14px;
        padding: 9px 22px;
    }

    .projects .block-section .block-elements .block-list h3{
        font-size: 20px;
        letter-spacing: 0.5px;
    }

    .projects .block-section .block-elements .block-list p{
        font-size: 16px;
        margin-bottom: 18px;
    }   
}