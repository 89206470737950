.headliner-module{
    padding: 80px 0;
    background: #f6f6e9;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
}

.headliner-module h2{
    margin-bottom: 40px;
    font-size: 38px;
}

.headliner-module button{
    font-size: 12px;
    padding: 8px 20px;
}


/* Media queries for tablet */
@media screen and (max-width: 768px) {

    .headliner-module {
        padding: 60px 30px;
    }

    .headliner-module h2 {
        font-size: 21px;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .headliner-module button {
        font-size: 14px;
        padding: 11px 26px;
    }

}

/* Media queries for mobile */
@media screen and (max-width: 480px) {

    .headliner-module {
        padding: 40px 30px;
    }

    .headliner-module h2 {
        font-size: 21px;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .headliner-module button {
        font-size: 14px;
        padding: 11px 26px;
    }

}