@import url("https://fonts.googleapis.com/css2?family=Noto+Serif&family=Poppins&display=swap");

html{
  overflow-x: hidden !important;
}

body{
  overflow-x: hidden !important;
}

:root {
  --primary: #0959E5;
  --secondary: #ffe01b;
  --white: #fff;
  --black: #000;
  --grey-dark: #333;
  --eee: #eee;
  --f5f5f5: #f5f5f5;
  --f8f9fa: #f8f9fa;
  --color-blue: #2699fb;
  --raisin-black: #22211D;
}

::-webkit-scrollbar{
  display: none;
}

.btn {
  background: var(--primary);
  color: var(--white);
  outline-style: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

.btn:hover {
  background: var(--black);
  color: var(--white);
}

.highlighter{
  color: var(--primary);
  font-family: 'Poppins', sans-serif;
}

.v-height {
  height: 100vh;
}

.flex {
  display: flex;
}

.position-relative {
  position: relative;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-sm-6,
.col-6,
.col {
  width: 50%;
}

.container {
  padding: 0 24px;
}

ul li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
}

a{
  text-decoration: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

span{
  font-family: 'Poppins', sans-serif;
}

.block {
  /* height: 100vh; */
  display: flex;
  justify-content: center; 
  align-items: center;
}

.block .block-container {
  width: 88%;
}

.mt-30{
  margin-bottom: 30px;
}

.grid-2{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

hr{
  margin-bottom: 28px;
  width: 50%;
  border: 1px solid #919191;
  margin: auto;
  display: block;
}
