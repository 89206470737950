.faq{
    margin: 60px 0;
}

.faq .block-container{
    width: 85%;
    margin: auto;
}

.faq .heading{
    position: relative;
    text-align: left;
}

.faq .heading h2{
    font-size: 40px;
}

.faq .block-section{
    margin-bottom: 25px;
    height: 50vh;
    grid-template-columns: 0.8fr 2.2fr;
}

.faq .image-section{
    display: flex;
    margin-bottom: 70px;
}

.faq .image-section .block-elements{
    margin: auto 0;
}

.faq .image-section .block-elements img{
    width: 540px;
    height: 335px;
}

.faq .image-section .block-list{
    width: 70%;
    margin: auto;
}

.faq .image-section .block-list h2{
    font-size: 37px;
    line-height: 1.4;
}

.faq .block-section .accordion-flush .accordion-item:first-child{
    border-top: 1px solid rgba(0,0,0,.125);
}

.faq .accordion-button{
    font-size: 22px;
    column-gap: 15px;
    font-family: 'Poppins', sans-serif;
}

.faq .accordion-button:not(.collapsed){
    background: var(--white) !important;
    color: var(--black);
}

.faq .accordion-button:focus{
    border-color: var(--white);
    box-shadow: none
}

.faq .accordion-button::after{
    background: #ffe01b url('https://upskilwebpagebucket.s3.ap-south-1.amazonaws.com/icon-chevron-down.png') no-repeat center;
    background-size: 18px auto;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
}

.faq .accordion-body{
    font-family: 'Poppins', sans-serif;
}

/* Media queries for tablet and larger screens */
@media screen and (min-width: 768px) {
    .faq .block-section {
        display: grid;
        grid-template-columns: 1fr 2fr;
        height: auto; /* Adjust height as needed */
    }

    .faq .block-elements {
        order: 1;
    }

    .faq .block-elements .heading {
        text-align: left;
    }

    .faq .accordion-button{
        column-gap: 18px;
        font-weight: 500;
    }

    .faq .accordion-body{
        font-size: 18px;
        padding: 1rem 20px;
    }
}

/* Media queries for mobile */
@media screen and (max-width: 767px) {

    .faq {
        height: auto;
        margin-top: 55px;
        margin-bottom: 20px;
    }
    .faq .heading h2{
        font-size: 37px;
    }

    .faq .block-section {
        display: block;
        height: auto; /* Adjust height as needed */
    }

    .faq .block-elements {
        order: 2;
    }

    .faq .image-section .block-list h2 {
        font-size: 26px;
        line-height: 1.4;
        margin-bottom: 20px;
        margin-top: 0;
        margin-bottom: 28px;
    }

    .faq .image-section .block-list{
        width: 100%;
    }

    .faq .image-section{
        flex-direction: column-reverse;
        margin-bottom: 55px;
    }

    .faq .image-section .block-elements img{
        width: 100%;
        height: auto;
    }

    .faq .block-elements .heading {
        text-align: left;
    }

    .faq .block-elements .heading h2{
        font-size: 26px;
        line-height: 1.4;
        margin-bottom: 30px;
    }

    .faq .accordion-button{
        font-size: 17px;
        padding-left: 10px;
        padding-right: 10px;
        column-gap: 10px;
        font-weight: 500;
    }

    .faq .accordion-button::after{
        height: 25px;
        width: 25px;
        background-size: 15px auto;
    }

    .faq .accordion-body{
        font-size: 14px;
        padding: 1rem 10px;
    }
}