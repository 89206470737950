.certificate .block-container .block-section{
  width: 50%;
  margin: auto;
}

.certificate .block-container .block-section:nth-child(2){
 padding-left: 80px;
}

.certificate .heading{
  position: relative;
  margin-bottom: 40px;
}

.certificate .certificate-img{
  height: auto;
  width: 100%;
}

.certificate .certificate-title{
  display: block;
  font-size: 38px;
  line-height: 1.4;
  text-transform: capitalize;
}

.certificate .highlighter{
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
}


.certificate .common-ul-style li {
  position: relative;
  margin-bottom: 35px;
  width: 100%;
  padding-right: 20px;
}

.certificate .common-ul-style li:before {
  content: "";
  position: absolute;
  left: -38px;
  top: 2px;
  width: 26px;
  height: 26px;
  background: url('https://upskilwebpagebucket.s3.ap-south-1.amazonaws.com/icon-checkmark.png');
  background-size: contain;
}

.certificate .common-ul-style li h3,
.certificate .bottom-text{
  font-weight: 600;
  font-size: 20px;
}

.certificate .common-ul-style li p{
  font-size: 14px;
}

.certificate .bottom-text{
  margin-top: 25px;
}


/* Media queries for tablet */
@media screen and (max-width: 768px) {
  .certificate{
    height: auto;
  }

  .certificate .heading {
    margin-bottom: 20px;
    padding: 20px;
  }

  .certificate .certificate-title {
    font-size: 28px;
    line-height: 1.4;
  }

  .certificate .common-ul-style{
    padding-top: 20px;
    padding-left: 10px;
    position: relative;
    left: 35px;
  }

  .certificate .common-ul-style li {
    margin-bottom: 20px;
    padding-right: 0;
  }

  .certificate .block-container .block-section {
    width: 100%;
  }

  .certificate .bottom-text{
    margin-top: 40px;
    margin-bottom: 35px;
    font-size: 21px;
    text-align: center;
  }

  .certificate .block-container .block-section img{
    margin: auto;
    display: block;
    width: 90%;
  }

}

/* Media queries for mobile */
@media screen and (max-width: 480px) {

  .certificate{
    height: auto;
  }

  .certificate .block-container {
    width: 100%;
    flex-direction: column-reverse !important;
  }

  .certificate .certificate-container{
    padding: 0 14px;
  }

  .certificate .block-container .block-section:nth-child(2){
    padding-left: 14px;
    padding-top: 25px;
  }

  .certificate .heading {
    margin-bottom: 20px;
    padding: 0 10px;
  }

  .certificate .certificate-title {
    font-size: 28px;
    line-height: 1.4;
  }

  .certificate .common-ul-style li p {
    font-size: 14px;
    margin-bottom: 26px;
  }

  .certificate .block-container .block-section img{
    margin: auto;
    display: block;
    width: 90%;
  }

  .certificate .common-ul-style{
    padding-top: 20px;
    padding-left: 10px;
    position: relative;
    left: 35px;
  }

  .certificate .common-ul-style li {
    margin-bottom: 0;
    padding-right: 0;
    width: 90%;
  }

  .certificate .common-ul-style li h3 {
    font-size: 18px;
    letter-spacing: 0.3px;
  }

  .certificate .common-ul-style li p {
    font-size: 14px;
    margin-bottom: 26px;
    width: 90%;
  }

  .certificate .bottom-text{
    margin-top: 40px;
    margin-bottom: 35px;
    font-size: 21px;
    text-align: center;
  }

  .certificate .common-ul-style li:before{
    height: 24px;
    width: 24px;
  }
}