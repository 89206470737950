.banner-section{
    height: 90vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.banner-section .banner-container{
    display: flex;
    width: 100%;
    flex-direction: row;
}

.banner-section .section-elements{
    margin: auto;
    display: block;
    width: 50%;
}

.banner-section .section-elements .container{
    padding: 0 45px;
    width: 90%;
}

.banner-section .section-elements .container .sub_text_top{
    font-size: 45px;
    margin-bottom: 25px;
}

.banner-section .section-elements .container .sub_text_middle{
    font-weight: 590;
    font-size: 20px;
    letter-spacing: 1;
    margin-bottom: 45px;
}

.banner-section .section-elements .container .highlighter{
    font-family: 'Poppins', sans-serif;
}

.banner-section .section-elements .container .sub_text_bottom{
    font-size: 13px;
    font-weight: 600;
    color: #006F46;
}

.banner-section .section-elements .banner-image{
    width: 100%;
    height: auto;
}

.banner-section .section-elements .banner-button{
    margin-top: 20px;
    margin-bottom: 12px;
}

.banner-section .section-elements .banner-button .btn{
    padding: 11px 25px;
}





/* Media queries for tablet */
@media screen and (max-width: 768px) {
    .banner-section {
        height: auto;
    }

    .banner-section .section-elements{
        width: 100%;
    }

    .banner-section .section-elements .container {
        width: 100%;
        padding: 20px;
    }

    .banner-section .section-elements .banner-image {
        width: 100%;
    }

    .banner-section .section-elements .banner-button {
        margin-top: 15px;
    }

    .banner-section .section-elements .banner-button .btn {
        padding: 10px 15px;
        font-size: 12px;
    }

    .banner-section .section-elements .container .sub_text_top{
        font-size: 36px;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: 18px;
    }

    .banner-section .section-elements .container .sub_text_middle{
        font-size: 18px;
        font-weight: 590;
        margin-bottom: 26px;
    }

    .banner-section .section-elements .container .sub_text_bottom{
        font-size: 13px;
        font-weight: 600;
        color: #006F46;
    }
}

/* Media queries for mobile */
@media screen and (max-width: 480px) {
    .banner-section {
        height: auto;
    }

    .banner-section .banner-container {
        flex-direction: column;
    }

    .banner-section .section-elements .container {
        width: 100%;
        padding: 20px;
    }

    .banner-section .section-elements .banner-image {
        width: 100%;
    }

    .banner-section .section-elements .banner-button {
        margin-top: 15px;
    }

    .banner-section .section-elements .banner-button .btn {
        /* padding: 10px 15px;
        font-size: 12px; */
        padding: 11px 26px;
        font-size: 14px;
    }

    .banner-section .section-elements .container .sub_text_top{
        font-size: 36px;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: 18px;
    }

    .banner-section .section-elements .container .sub_text_middle{
        font-size: 18px;
        font-weight: 590;
        margin-bottom: 26px;
        /* width: 89%; */
    }

    .banner-section .section-elements .container .sub_text_bottom{
        font-size: 13px;
        font-weight: 600;
        color: #006F46;
    }
}




