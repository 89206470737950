.modal {
    background-color: rgba(0, 0, 0, 0.5);
    font-family: 'Poppins', sans-serif;
    transition: transform 0.3s ease-in-out;
    /* max-width: 435px; */
}

.modal .modal-dialog{
    max-width: 435px;
}

.modal .modal-body-header h5{
    margin: 0;
}

.modal .modal-buttons a {
    text-decoration: none;
    color: var(--white);
    text-align: center;
    padding: 12px 25px;
}

.modal .modal-buttons a img{
    height: 20px;
    width: auto;
}

.modal .modal-buttons a:nth-child(1){
    background: var(--black);
}

.modal .modal-buttons a:nth-child(2){
    background: var(--primary);
}

.modal .modal-body-header .arrow-button{
    border: none;
    outline: none;
    background: transparent;
}

.modal .modal-body-header .arrow-button img{
    height: 18px;
}

.modal input{
    border-width: 1px;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #dfdfdf;
    color: #393f41;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
}

.modal input:focus,
.modal input:active,
.modal input::selection{
    outline: none;
}

.modal .modal-body-footer p{
 text-align: center;
 margin-bottom: 0;
 
}

.modal .modal-body-footer p a{
    text-decoration: none;
    color: var(--raisin-black);
    font-weight: 600;
} 

.modal .modal-body-footer .footer-text a{
    font-size: 12px;
    font-weight: 400;
}

.modal .modal-body-footer button{
    outline: none;
    background: none;
    border: none;
    background: var(--primary);
    width: 100%;
    text-align: center;
    padding: 12px 25px;
    color: var(--white);
    cursor: pointer;
}

.modal .modal-body-footer button:disabled{
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
}